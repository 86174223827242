import React, { useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "./styles/OurClients.css";
import { motion } from "framer-motion";

const OurClients = () => {
  const constraintsRef = useRef(null);

  const items = [
    {
      src:'/images/ourclients/ctl-logistics.png',
      name:'ctl-logitics'
    },
    {
      src:'/images/ourclients/alisped.png',
      name:'alisped-logitics'
    },
    {
      src:'/images/ourclients/dahnay-logitics-growthenow-logo.png',
      name:'dahnay-logitics'
    },
    {
      src:'/images/ourclients/sri-maninath-logistics.png',
      name:'sri-maninath-logistics'
    },
    {
      src:'/images/ourclients/shravya-logistics-pvt.jpg',
      name:'shravya-logistics'
    },
    {
      src:'/images/ourclients/emirates-logistics.png',
      name:'emirates-logistics'
    },
    {
      src:'/images/ourclients/emu-line.png',
      name:'emu-line-logitics'
    },
    {
      src:'/images/ourclients/freight-consolidators.webp',
      name:'freight-consolidators'
    },
    {
      src:'/images/ourclients/lx-pantosh.png',
      name:'lx-pantos-logitics'
    },
    {
      src:'/images/ourclients/nnr-logistics.png',
      name:'nnr-logistics'
    },
    {
      src:'/images/ourclients/transmec-log.png',
      name:'transmec-logistics'
    },
    {
      src:'/images/ourclients/greenwich-logistics.png',
      name:'greenwich-logistics'
    },
  ];

  return (
    <div className="carousel-container">
      <div className="our-clients-section">
        <div className="container">
          <div className="our-clients-top row">
            <div className="col-md-8 our-clients-top-content">
              <span className="our-clients-top-subtitle">OUR CLIENTS</span>
              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                viewport={{
                  once: true,
                  amount: 0.2,
                }}
              >
                Discover the companies we{" "}
                <span className="subtitle-tag">proudly</span> work with
                <span className="right-down-arrow">&#8628;</span>
              </motion.h2>
            </div>
            <div className="col-md-4 our-clients-tag-button">
              <motion.button
                className="drag-button"
                ref={constraintsRef}
                style={{ overflow: "hidden",cursor:"auto" }}
              >
                <motion.div
                  drag
                  dragConstraints={constraintsRef}
                  className="drag-content"
                >
                  Clients
                </motion.div>
              </motion.button>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.3 }}
            viewport={{
              once: true,
              amount: 0.3,
            }}
            className="carousel-container"
          >
            <Splide
              options={{
                type: "loop", // Enables infinite scrolling
                perPage: 5, // Number of slides to show per page
                perMove: 1, // Number of slides to move per action
                autoplay: true, // Enable autoplay
                interval: 1800, // Autoplay interval in milliseconds
                pagination: false, // Show pagination dots
                arrows: false, // Show navigation arrows
                pauseOnHover: false,
                breakpoints: {
                  768: {
                    perPage: 3,
                  },
                  480: {
                    perPage: 2,
                  },
                },
              }}
            >
              {items.map((item, index) => (
                <SplideSlide key={index}>
                  <div className="slide-item">
                    <img src={item.src} alt={item.name} />
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OurClients;
